import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const components = {
  Text: {
    variants: {
      docentLabel: {
        color: '#A6A6A6'
      }
    }
  },
  Heading: {
    baseStyle: {
      fontWeight: 'light'
    }
  },
  Button: {
    variants: {
      docentPrimary: (props) => ({
        bg: mode('#000000', 'gray.50')(props),
        color: mode('white', 'black')(props),
        _hover: {
          bg: mode('gray.700', 'gray.300')(props),
          _disabled: {
            bg: 'gray.700'
          }
        }
      }),
      docentSecondary: {
        border: '1px dotted #000000',
        _hover: {
          bg: 'gray.100'
        }
      }
    }
  }
};

const customTheme = extendTheme({
  fonts: {
    heading: 'WTMonarchNova'
  },
  colors: {
    docentAccent: 'var(--chakra-colors-blue-500)',
    docentHighlight: '#fbfbfb',
    docentGray: '#F2F2F2',
    docentLabel: '#A6A6A6',
    docentSoftWarning: '#fff3e0',
    docentSoftGreen: '#c8e6c9',
    docentSoftScrapping: 'var(--chakra-colors-gray-100)',
    docentSoftToValidate: 'var(--chakra-colors-gray-50)',
    // Small trick for 500 to display black Checkbox...
    docentBlackCheckbox: {
      50: '#f2f2f2',
      100: '#d9d9d9',
      200: '#bfbfbf',
      300: '#a6a6a6',
      400: '#8c8c8c',
      500: '#000000',
      600: '#595959',
      700: '#404040',
      800: '#262626',
      900: '#0d0d0d'
    }
  },
  components
});

export default customTheme;
