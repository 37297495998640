import { createContext, useContext, useEffect, useState } from 'react';

const StoreContext = createContext(null);

export function StoreWrapper({ children }) {
  let impersonate_gallery_id: string;
  let impersonate_gallery_name: string;
  let impersonate_institution_id: string;
  let impersonate_institution_name: string;
  let table_filters: [];
  if (typeof window !== 'undefined') {
    impersonate_gallery_name =
      localStorage.getItem('impersonate_gallery_name') === 'null'
        ? null
        : localStorage.getItem('impersonate_gallery_name');
    impersonate_gallery_id =
      localStorage.getItem('impersonate_gallery_id') === 'null'
        ? null
        : localStorage.getItem('impersonate_gallery_id');
    impersonate_institution_name =
      localStorage.getItem('impersonate_institution_name') === 'null'
        ? null
        : localStorage.getItem('impersonate_institution_name');
    impersonate_institution_id =
      localStorage.getItem('impersonate_institution_id') === 'null'
        ? null
        : localStorage.getItem('impersonate_institution_id');
    table_filters = JSON.parse(localStorage.getItem('table_filters'));
  }
  const [store, setStore] = useState({
    impersonate_gallery_name: impersonate_gallery_name,
    impersonate_gallery_id: impersonate_gallery_id,
    impersonate_institution_name: impersonate_institution_name,
    impersonate_institution_id: impersonate_institution_id,
    artist_name: '',
    artwork_id: null,
    empty_fields: [],
    to_validate: false,
    submitted_artworks: false,
    scrapped_artworks: false,
    table_filters: table_filters,
    artworks_validated_count: 0
  });

  useEffect(() => {
    if (typeof window !== 'undefined' && store.impersonate_gallery_id) {
      localStorage.setItem('impersonate_gallery_name', store.impersonate_gallery_name);
      localStorage.setItem('impersonate_gallery_id', store.impersonate_gallery_id);
      localStorage.setItem('impersonate_institution_name', null);
      localStorage.setItem('impersonate_institution_id', null);
    }
    if (typeof window !== 'undefined' && store.impersonate_institution_id) {
      localStorage.setItem('impersonate_institution_name', store.impersonate_institution_name);
      localStorage.setItem('impersonate_institution_id', store.impersonate_institution_id);
      localStorage.setItem('impersonate_gallery_name', null);
      localStorage.setItem('impersonate_gallery_id', null);
    }
    if (typeof window !== 'undefined') {
      store.table_filters &&
        localStorage.setItem('table_filters', JSON.stringify(store.table_filters));
    }
  }, [store]);

  return <StoreContext.Provider value={{ store, setStore }}>{children}</StoreContext.Provider>;
}

export function useStoreContext() {
  return useContext(StoreContext);
}
