import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
      /* latin */
 
      @font-face {
        font-family: "WTMonarchNova";
        src: url("/fonts/WTMonarchNova-Roman.woff") format("woff");
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      `}
  />
);

export default Fonts;
