import { ApolloClient, HttpLink, split, from, fromPromise } from '@apollo/client';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import fetch from 'isomorphic-unfetch';
import ws from 'isomorphic-ws';
import React from 'react';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { onError } from '@apollo/client/link/error';
import { signOut, getSession } from 'next-auth/client';
import jwtDecode from 'jwt-decode';

const getNewToken = async () => {
  const session = await getSession();
  return session?.token;
};

const createHttpLink = (token: string, isAdmin: boolean) => {
  const getHttpUri = () => {
    return process.env.NEXT_PUBLIC_API_URL;
  };

  const httpLink = token
    ? new HttpLink({
        uri: getHttpUri(),
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Hasura-Role': isAdmin ? 'app-admin' : 'gallery_user'
        },
        fetch
      })
    : new HttpLink({
        uri: getHttpUri(),
        credentials: 'include',
        fetch
      });
  return httpLink;
};

const createWSLink = (token: string) => {
  return new WebSocketLink(
    new SubscriptionClient(
      process.env.NEXT_PUBLIC_WS_URL,
      {
        lazy: true,
        reconnect: true,
        connectionParams: async () => {
          return {
            headers: { Authorization: `Bearer ${token}` }
          };
        }
      },
      ws
    )
  );
};

let apolloClient: ApolloClient<NormalizedCacheObject>;

export const createApolloClient = (token: string, isAdmin: boolean) => {
  const ssrMode = typeof window === 'undefined';

  const link = !ssrMode
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
          );
        },
        createWSLink(token),
        createHttpLink(token, isAdmin)
      )
    : createHttpLink(token, isAdmin);

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case 'invalid-jwt':
            return fromPromise(
              getNewToken().catch((error) => {
                // Handle token refresh errors e.g clear stored tokens, redirect to login
                signOut({ callbackUrl: '/' });
              })
            )
              .filter((value) => Boolean(value))
              .flatMap((accessToken) => {
                const oldHeaders = operation.getContext().headers;
                // modify the operation context with a new token
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${accessToken}`
                  }
                });

                // retry the request, returning the new observable
                return forward(operation);
              });
        }
      }
    }
  });

  return new ApolloClient({ ssrMode, link: from([errorLink, link]), cache: new InMemoryCache() });
};

export const initializeApollo = (initialState = {}, token: string, isAdmin: boolean) => {
  const _apolloClient = apolloClient ?? createApolloClient(token, isAdmin);

  if (initialState) {
    const existingCache = _apolloClient.extract();

    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  if (typeof window === 'undefined') {
    return _apolloClient;
  }

  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
};

export function useApollo(initialState: any, token: string, isAdmin: boolean) {
  const store = React.useMemo(
    () => initializeApollo(initialState, token, isAdmin),
    [initialState, token, isAdmin]
  );
  return store;
}
