import { ApolloProvider } from '@apollo/client';
import Layout from 'components/Layout';
import { useApollo } from 'lib/apolloClient';
import { Provider as NextAuthProvider } from 'next-auth/client';
import { ChakraProvider } from '@chakra-ui/react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import ISession from 'types/session';
import Fonts from '../components/fonts';
import customTheme from '../lib/chakra';
import { StoreWrapper } from '../context/store';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { signIn } from 'next-auth/client';
import mixpanel from 'mixpanel-browser';

import { pageview, AnalyticsScript } from '../utils/analytics';

import '../style/Table.scss';
import dynamic from 'next/dynamic';

const App = ({ Component, pageProps }: AppProps) => {
  const { session }: { session: ISession } = pageProps;
  const apolloClient = useApollo(pageProps.initialApolloState, session?.token, session?.isAdmin);

  const router = useRouter();
  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => router.events.off('routeChangeComplete', pageview);
  }, [router.events]);

  useEffect(() => {
    if (session?.error === 'RefreshAccessTokenError') {
      signIn(); // Force sign in to hopefully resolve error
    }
  }, [session]);

  return (
    <>
      <AnalyticsScript />
      <Head>
        <link rel="shortcut icon" href="/images/favicon.ico" />
      </Head>
      <NextAuthProvider session={session}>
        <ApolloProvider client={apolloClient}>
          <ChakraProvider theme={customTheme}>
            <Fonts />
            <StoreWrapper>
              <DndProvider backend={HTML5Backend}>
                <Component {...pageProps} />
              </DndProvider>
            </StoreWrapper>
          </ChakraProvider>
        </ApolloProvider>
      </NextAuthProvider>
    </>
  );
};

export default App;
